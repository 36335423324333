


























































import Vue from "vue";
import { Component } from "vue-property-decorator";

import Banner from "@/components/Banner.vue";
import NewHeader from "@/components/NewHeader.vue";
import GameListComponent from "@/components/GameList.vue";
import GameListCounter from "@/components/GameListCounter.vue";
import GameListSorter from "@/components/GameListSorter.vue";

import { Game, GameList, Genre } from "@/store/modules/games";

@Component({
  components: {
    Banner,
    NewHeader,
    GameListComponent,
    GameListCounter,
    GameListSorter,
  },
})
export default class GameListView extends Vue {
  selectValue = "title";

  get games2(): Game[] {
    switch (this.selectValue) {
      case "new":
        return this.$store.direct.getters.games.gamesSortedDate;
      case "popular":
        return this.$store.direct.getters.games.gamesSortedPopular;
      case "title":
      default:
        return this.$store.direct.getters.games.gamesSortedAlpha;
    }
  }

  created(): void {
    this.selectValue = this.$router.currentRoute.params.sort || "title";
  }

  changed(value: string): void {
    history.replaceState(null, "", this.$router.resolve({ params: { sort: value } }).href);
  }

  get games(): Game[] {
    if (this.$router.currentRoute.params.segment)
      return this.games2.filter((g) => this.gameList?.games.includes(g._id));
    return this.games2.filter((g) => g.genre === this.genre?._id);
  }

  get gameList(): GameList | undefined {
    return this.$store.direct.state.games.gameLists.find(
      (gl) => gl.urlSegment === this.$router.currentRoute.params.segment
    );
  }

  get genre(): Genre | undefined {
    return this.$store.direct.state.games.genres.find(
      (g) => g.name.toLowerCase() === this.$router.currentRoute.params.genre.toLowerCase()
    );
  }

  get title(): string {
    if (this.$router.currentRoute.params.segment) return this.$t(`gameLists.${this.gameList?._id}.title`).toString();
    return this.$t(`genres.${this.genre?._id}.name`).toString();
  }
}
